
import React, { useState, useEffect, useContext } from "react";
import Nav from "./Nav";
import Leftsidebar from "./Sidebar/Sidebar.js";
import { Button } from '@mui/material';
import { collection, addDoc, Timestamp } from "firebase/firestore"; 
import { db } from "./Auth/firebase.js";
import { AuthContext } from "./Auth/AuthProvider.js";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import categories_react_select from "./Data/categories_transformer.js";

const CreatePost = ( {question = true} ) => {
    const { user, loading } = useContext(AuthContext);
    const [selectedTags, setSelectedTags] = useState([]);
    const navigate = useNavigate();

    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");

    const handleTitle = (e) =>{
        setTitle(e.target.value);
    }

    const handleContent = (e) => {
        setContent(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "posts"), {
                question: true,
                title: title,
                content: content,
                uid: user.uid,
                timestamp: Timestamp.now(),
                tags: selectedTags.map(tag => tag.value),
            });
            console.log("Document written with ID: ", docRef.id);
            navigate('/')
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    };
    const selectorStyles = {
        control: (provided) => ({
            ...provided,
            '@apply bg-gray-100 border border-gray-400 p-2 rounded-md shadow-md': {},
          }),
          menu: (provided) => ({
            ...provided,
            '@apply bg-blue-100 rounded-lg': {},
          }),
          option: (provided, state) => ({
            ...provided,
            '@apply py-2 px-4 cursor-pointer': {},
            '@apply bg-blue-500 text-white': state.isSelected,
            '@apply bg-blue-200': state.isFocused && !state.isSelected,
            '@apply bg-white text-black': !state.isSelected && !state.isFocused,
          }),
          singleValue: (provided) => ({
            ...provided,
            '@apply text-gray-700': {},
          }),

        };
      
    return (
        <div className="postform-page">
            <Nav />
            <Leftsidebar />
            <main className="flex-grow p-6">
                <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
                    {question ? 'Ask a Question' : 'Create a Post'}
                </h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                    <label htmlFor="contentTitle" className="block text-sm font-medium text-gray-700 mb-1">
                        Title / Description
                    </label>
                    <input
                        id="contentTitle"
                        name="title"
                        type="text"
                        required
                        value={title}
                        onChange={handleTitle}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    </div>
                    <div>
                    <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
                        Content
                    </label>
                    <textarea
                        id="content"
                        name="content"
                        rows="5"
                        maxLength="500"
                        placeholder="What's on your mind?"
                        required
                        value={content}
                        onChange={handleContent}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                    ></textarea>
                    </div>
                    <div>
                    <Select
                        isMulti
                        value={selectedTags}
                        onChange={(tags) => setSelectedTags(tags)}
                        options={categories_react_select}
                        placeholder="Select or create tags..."
                        styles={selectorStyles}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                    </div>
                    <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                    >
                    Submit
                    </button>
                </form>
                </div>
            </main>
        </div>
      );
};

export default CreatePost;
