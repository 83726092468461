
import React, { useState } from "react";
import Nav from "./Nav";
import Leftsidebar from "./Sidebar/Sidebar.js";
import PostsContainer from "./Posts/PostsContainer.js";

const Home = () => {
    const [category, setCategory] = useState("All");
    return (
        <div style= {{display:'flex', flexWrap: 'wrap', flexDirection: "row"}}>
            <Nav />
            <Leftsidebar setCategory={setCategory}/>
            <PostsContainer category={category}/>
        </div>
    );
};

export default Home;
