import { useEffect, useRef, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PeopleItem = ({ _id, person }) => {
    const firstname = person?.firstname
    const lastname = person?.lastname
    const role = person?.role
    const link = '/profile/' + person?.link
    const address = person?.email?.split('@').pop()
    console.log(person);

    return (
        <div className="flex flex-col border rounded bg-white relative border-zinc-300	">

            {/* User */}
            <div className="flex justify-between px-3 py-2.5 border-b items-center">
                <div className="flex space-x-3 items-center">
                    <Link to={link}><img draggable="false" src="http://localhost:4000/default_profile_pic2.jpeg" className="w-10 h-10 rounded-full object-cover" alt="avatar" /></Link>
                    <div className="flex flex-col">
                        <Link to={link} className="text-black text-sm font-semibold">
                            {firstname} {lastname}: {role}
                        </Link>
                        <Link to={link} className="text-black text-sm">
                            {address}
                        </Link>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default PeopleItem