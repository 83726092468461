import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Nav from "../Nav.js";
import Leftsidebar from "../Sidebar/Sidebar.js";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios";
import PostItem from "./PostItem.js";
import AnswerItem from "./AnswerItem.js";
import { db } from "../Auth/firebase.js";
import { collection, addDoc, getDoc, Timestamp } from "firebase/firestore"; 
import { backend_url } from "../../config.js";
import { AuthContext } from "../Auth/AuthProvider.js";

const Replies = () => {
    const [reply, setReply] = useState("");
    const [postReplies, setPostReplies] = useState([]);
    const [postCreator, setPostCreator] = useState(null);
    const [post, setPost] = useState(null);
    const { postId } = useParams();
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const { user, loading, accessToken } = useContext(AuthContext);


    useEffect(() => {
        fetch(backend_url + "/api/postfromid?post_id=" + postId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(data => {
            fetchReplies(data);
            setPost(data);
            return data;
        })
        .then(data => {
            fetch(backend_url + "/api/userfromid?user_id=" + data.uid, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => res.json())
            .then(data => {
                setPostCreator(data);
            })
            .catch(error => {
                console.error("Failed to retrieve creator info", error);
            });
        })
        .catch(error => {
            console.error("Error retrieving post or children", error);
        });
    }, [postId]);

    function fetchReplies(cur_post) {
        setPostReplies([]); // Reset the state at the start
        if (cur_post && cur_post.replies) {
            const fetchPromises = cur_post.replies.map(replyId => {
                return fetch(backend_url + "/api/postfromid?post_id=" + replyId, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => res.json())
                .catch((err) => {
                    console.error("Error fetching reply:", err);
                    return null;
                });
            });

            Promise.all(fetchPromises)
                .then((results) => {
                    const validResults = results.filter(result => result !== null).reverse();
                    setPostReplies(validResults);
                })
                .finally(() => {
                    setHasMorePosts(false);
                });
        } else {
            setHasMorePosts(false);
        }
    }

    const handleSubmitReply = async (e) => {
        e.preventDefault();
        try {
            const newDoc = {
                content: reply,
                uid: user.uid,
                parent: postId,
                timestamp: Timestamp.now(),
            };
            const docRef = await addDoc(collection(db, "posts"), newDoc);
            const added_doc = await getDoc(docRef);
            axios.post(backend_url + 
                "/update/post/add_reply?post_id=" + postId + 
                "&reply_id=" + docRef.id)
            .catch((err) => console.log(err));

            setPostReplies((postReplies) => [added_doc.data(), ...postReplies]);
            setReply("");
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    return (
        <div className="flex flex-wrap">
            <Nav />
            <Leftsidebar />

            <main className="flex flex-col grow p-6 bg-gray-50">
                <PostItem key={postId} post={post} postCreator={postCreator} reply_page={true}/>

                <form onSubmit={handleSubmitReply} className="mb-6">
                    <label htmlFor="reply" className="block text-lg font-medium text-gray-700 mb-2">Answer Question</label>
                    <textarea
                        rows={3}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        type="text"
                        name="reply"
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Write here..."
                    />
                    <button
                        type="submit"
                        className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
                    >
                        SEND
                    </button>
                </form>

                <InfiniteScroll
                    dataLength={postReplies.length}
                    next={() => fetchReplies(post)}
                    hasMore={hasMorePosts}
                    loader={<h4 className="text-center text-gray-500 animate-pulse">Loading...</h4>}
                    endMessage={
                        <p className="text-center text-gray-600 mt-4">
                            <b>That's all!</b>
                        </p>
                    }
                    refreshFunction={() => fetchReplies(post)}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                        <h3 className="text-center text-gray-500">&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                        <h3 className="text-center text-gray-500">&#8593; Release to refresh</h3>
                    }
                    className="space-y-4"
                >
                    <div className="space-y-4">
                        {postReplies?.map((item) => (
                                <AnswerItem key={item.id} post={item}/>
                        ))}
                    </div>
                </InfiniteScroll>
            </main>
        </div>
    );
};

export default Replies;
