import { useEffect, useContext, useState } from 'react'
// import { addComment, likePost, savePost } from '../../actions/postAction';
import { commentIcon, emojiIcon, likeIconOutline, moreIcons, saveIconFill, saveIconOutline, shareIcon, likeFill} from '../SvgIcons.jsx'
import { Picker } from 'emoji-mart'
import ScrollToBottom from 'react-scroll-to-bottom';
import moment from 'moment';
import {backend_url} from "../../config.js";
import { Link, useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import TopAnswer from "./TopAnswer.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { AuthContext } from "../Auth/AuthProvider.js";

const PostItem = ({ post, postCreator, answerers, savedBy, createdAt, setUsersDialog, reply_page = false}) => {
    const creatorId = post?.uid
    const content = post?.content
    const title = post?.title
    const _id = post?.id
    let num_likers = post?.likers?.length
    if (num_likers == undefined) {
        num_likers = 0
    }

    const navigate = useNavigate();

    const { user, loading, accessToken } = useContext(AuthContext);

    const [allLikers, setAllLikers] = useState(num_likers);
    const [liked, setLiked] = useState(false);
    const [isPostCreator, setIsPostCreator] = useState(false);

    useEffect(() => {
        setIsPostCreator(postCreator?.uid == user?.uid)
    }, [user]);
    

    // const { user } = useSelector((state) => state.user);
    // const { loading, post } = useSelector((state) => state.postDetails);

    const [allSavedBy, setAllSavedBy] = useState(savedBy);

    useEffect(() => {
        if (user && post?.likers && post.likers.includes(user.uid)) {
            setLiked(true)
        }
    }, [user])

    const [saved, setSaved] = useState(false);
    const [comment, setComment] = useState("");
    const [viewComment, setViewComment] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);

    const [likeEffect, setLikeEffect] = useState(false);

    const handleLike = async () => {
        try {
            if (!liked) {
                fetch(backend_url + "/update/post/add_like?post_id=" + _id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
                })
                setAllLikers(allLikers + 1);
            } else {
                fetch(backend_url + "/update/post/remove_like?post_id=" + _id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
                })
                setAllLikers(allLikers - 1);
            }
            setLiked(!liked);

        } catch(e) {
            console.error("Did not retrieve creator info", creatorId, e);
        }
    }

    const handleComment = async (e) => {
        e.preventDefault();
        // await dispatch(addComment(_id, comment));
        // setComment("");
        // const { data } = await axios.get(`/api/v1/post/detail/${_id}`)
        // setAllComments(data.post.comments)
    }

    const handleSave = async () => {
        setSaved(!saved);
        // await dispatch(savePost(_id));
        // const { data } = await axios.get(`/api/v1/post/detail/${_id}`)
        // setAllSavedBy(data.post.savedBy)
    }

    const handleLikeModal = () => {
    }

    const setLike = () => {
        setLikeEffect(true)
        setTimeout(() => {
            setLikeEffect(false)
        }, 500)
        if (liked) {
            return;
        }
        handleLike();
    }

    const deletePost = async () => {
        try {
            await fetch(backend_url + "/delete/post?post_id=" + _id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer: " + accessToken,
                }
            })
            console.log("Deleted Post")
        } catch(e) {
            console.error("Did not delete post", creatorId, e)
        }
    }
    // useEffect(() => {
    //     setLiked(allLikers.some((u) => u._id === user._id))
    // }, [allLikers]);

    // useEffect(() => {
    //     setSaved(allSavedBy.some((id) => id === user._id))
    // }, [allSavedBy]);


    return (
        <div className="flex flex-col border rounded bg-white relative border-zinc-300	drop-shadow	">
            <UserDetailsComponent postCreator={postCreator}/>

            {/* Content */}
            <div className="flex flex-col relative">
                <div className="font-sans text-xl pb-1 font-bold px-1"> {title} </div>
                <div className="whitespace-pre-wrap px-1">{content}</div>
                {likeEffect &&
                    <img draggable="false" height="80px" className="likeEffect" alt="heart" src="" />
                }
            </div>

            {/* like comment container */}
            <div className="flex flex-col px-1 space-y-1 border-b pb-2 mt-2">

                {/* icons container */}
                <div className="flex items-center justify-between py-2">
                    <div className="flex space-x-4">
                        <Tooltip title="Like Post">
                            <button onClick={handleLike}>{liked ? likeFill : likeIconOutline}</button>
                        </Tooltip>
                        <Tooltip title="Go to Discussion">
                            <button onClick={() => {navigate('/post/' + _id)}}>{commentIcon}</button>
                        </Tooltip>
                    </div>
                    {/* <button onClick={handleSave}>{saved ? saveIconFill : saveIconOutline}</button> */}
                    {isPostCreator && 
                        <button onClick={deletePost}>
                            <Tooltip title="Delete Post">
                                <DeleteOutlineIcon/>
                            </Tooltip>
                        </button>
                    }
                </div>

                {/* num likes  */}
                <span onClick={handleLikeModal} className="font-semibold text-sm">{allLikers} Likes </span>

                {/* Answerers  */}
                {!reply_page &&
                    <Tooltip 
                        title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                <ol className="no-bullets">
                                    {answerers?.map(answerer => 
                                    <li>
                                        <Link to={`/profile/` + answerer.link}>
                                                {answerer.firstname} {answerer.lastname}
                                        </Link>
                                    </li>
                                    )}
                                    <li>
                                        ...
                                    </li>
                                </ol>
                            </div>
                        }
                        placement="bottom-start"
                    >
                        <span onClick={() => {navigate('/post/' + _id)}} className="font-semibold text-sm cursor-pointer">{post?.replies == undefined ? 0 : post.replies.length} Answers </span>
                    </Tooltip>
                }


                {/* time */}
                {/* {allComments.length > 0 ?

                    <span onClick={() => setViewComment(!viewComment)} className="text-[13px] text-gray-500 cursor-pointer">
                        {viewComment ? "Hide Comments" :
                            allComments.length === 1 ?
                                `View ${allComments.length} Comment` :
                                `View All ${allComments.length} Comments`
                        }
                    </span> :

                    <span className="text-[13px] text-gray-500">No Comments Yet!</span>

                }
                <span className="text-xs text-gray-500 cursor-pointer">{moment(createdAt).fromNow()}</span>

                {viewComment &&
                    <ScrollToBottom className="w-full h-52 overflow-y-auto py-1">
                        {allComments.map((c) => (
                            <div className="flex items-start mb-2 space-x-2" key={c._id}>
                                <img draggable="false" className="h-7 w-7 rounded-full object-cover mr-0.5" src={c.user.avatar} alt="avatar" />
                                <Link to={`/${c.user}`} className="text-sm font-semibold hover:underline">{c.user.username}</Link>
                                <p className="text-sm">{c.comment}</p>
                            </div>
                        ))}
                    </ScrollToBottom>
                } */}

            </div>

            {/* Reply */}
            {!reply_page && post?.replies && post.replies.length > 0 && (
                    <TopAnswer answer_id={post.replies[0]} />
            )}

        </div >
    )
}

export default PostItem