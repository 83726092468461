import React from "react";
import Nav from "../Nav.js";
import RegisterComponent from "./RegisterComponent.js";
import { Link } from "react-router-dom";

const Register = () => {
    return (
        <>
            <Nav />
            <RegisterComponent/>
            <p>
                Have an account? <Link to='/login'>Sign in</Link>
            </p>
        </>
    );
};

export default Register;
