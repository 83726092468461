import React, {useState, useEffect, useContext} from 'react';
import { FaEnvelope, FaPhone, FaLinkedin } from 'react-icons/fa';
import Nav from "../Nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import {backend_url} from "../../config.js";
import { AuthContext } from "../Auth/AuthProvider";
import { Button, Input, Modal } from 'antd';
import UpdateExperience from "./UpdateExperience.js";

const UserProfile = () => {
    const { user: user_link } = useParams();
    const [profile, setProfile] = useState(true);

    const { user, loading, accessToken } = useContext(AuthContext);
    const [isUser, setIsUser] = useState(false);
    const [summaryEditing, setSummaryEditing] = useState(false);
    const [editedSummary, setEditedSummary] = useState(profile.summary);
    const [skills, setSkills] = useState([]);


    useEffect(() => {
        fetch(backend_url + "/get/profilefromlink?link=" + user_link, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(data => {
            setEditedSummary(data.summary);
            setSkills(data.skills);
            setProfile(data);
            return data;
        })
        .catch(error => {
            console.error("Error retrieving post or children", user_link, error)
        })
    }, []);

    useEffect(() => {
        if (profile?.uid && user?.uid && profile?.uid == user?.uid) {
            setIsUser(true);
        }
    }, [user, profile]);

    const submitSummaryEdit = () => {
        fetch(backend_url + "/update/profile/summary?uid=" + user.uid, {
            method: "POST",
            body: JSON.stringify({ newSummary: editedSummary }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer: " + accessToken,
            }
        })
        .catch(error => {
            console.error("Error submitting summary edit", user_link, error)
        })
        setSummaryEditing(false);
    };
    


    return (
        <div style= {{display:'flex', flexWrap: 'wrap', flexDirection: "row"}}>
            <Nav />

            <div className="bg-gray-100 min-h-screen p-4 sm:p-6 grow">
                <div className="w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
                    <div className="bg-cover bg-center h-32 sm:h-40" style={{ backgroundImage: `url('/path/to/cover.jpg')` }}></div>
                    <div className="flex flex-col items-center p-4 sm:p-6 -mt-16">
                        <img 
                            src="/path/to/avatar.jpg" 
                            alt="User Avatar" 
                            className="w-24 h-24 sm:w-28 sm:h-28 rounded-full border-4 border-white shadow-lg"
                        />

                        <h2 className="mt-3 text-xl sm:text-2xl font-semibold text-gray-800">
                            {profile?.firstname} {profile?.lastname}
                        </h2>

                        <p className="text-sm sm:text-base text-gray-600">
                            {profile?.role}
                        </p>

                        <p className="flex items-center text-gray-700">
                                <FaEnvelope className="mr-2 text-blue-600" /> {profile?.email}
                        </p>

                        {/* SUMMARY */}
                        <div className="profile-summary-section flex flex-col w-full max-w-2xl mx-auto relative">
                            
                            {editedSummary && (
                                <p className="break-words text-center mb-4">
                                    {editedSummary}
                                </p>
                            )}
                            {!editedSummary && isUser && (
                                <p className="break-words text-center text-gray-500	">
                                    Add summary here
                                </p>
                            )}

                            
                            {isUser && (
                                <div>
                                    <Button 
                                        className="absolute top-0 right-0" 
                                        onClick={() => setSummaryEditing(true)}
                                    >
                                        Edit
                                    </Button>

                                    <Modal
                                        title="Edit Your Summary"
                                        open={summaryEditing}
                                        onOk={() => {submitSummaryEdit();}}
                                        onCancel={() => setSummaryEditing(false)}
                                    >
                                        <Input.TextArea
                                        value={editedSummary}
                                        onChange={(e) => setEditedSummary(e.target.value)}
                                        rows={4}
                                            />
                                    </Modal>
                                </div>
                            )}
                        </div>

                        {/* <div className="flex mt-4 space-x-2">
                            <button className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 shadow">
                                Connect
                            </button>
                            <button className="bg-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm hover:bg-gray-400 shadow">
                                Message
                            </button>
                        </div> */}
                        <div className="p-4 sm:p-6 space-y-2 text-sm sm:text-base flex flex-row">
                            <p className="flex items-center text-gray-700">
                                <FaLinkedin className="mr-2 text-blue-700 fa-5x" />
                                <a href="#" className="hover:underline">{profile?.linkedin}</a>
                            </p>
                        </div>
                    </div>
 
 


                    {/* EXPERIENCE */}
                    <div className="p-4 sm:p-6 bg-gray-50">
                        <div className="flex flex-row relative">
                            <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">Experience</h3>

                            {isUser && (
                                <UpdateExperience profile={profile} setProfile={setProfile} />
                            )}

                        </div>
                        <div className="space-y-2">
                            {profile?.experience?.map((exp, index) => (
                                <div key={index} className="py-2 relative">
                                    <h4 className="text-base sm:text-lg font-bold text-gray-800">{exp.jobTitle}</h4>
                                    <p className="text-sm text-gray-600">{exp.company}</p>
                                    <p className="text-xs text-gray-500">{exp.startDate} to {exp.endDate}</p>
                                    <p className="text-xs text-gray-500">{exp.description}</p>
                                    {isUser && (
                                        <UpdateExperience profile={profile} setProfile={setProfile} index={index} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* SKILLS */}
                    {/* <div className="p-4 sm:p-6">
                        <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">Skills</h3>
                        <div className="flex flex-wrap">
                            {profile?.skills?.map((skill, index) => (
                                <span 
                                    key={index} 
                                    className="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-xs sm:text-sm font-medium m-1 shadow-sm"
                                >
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div> */}


                </div>
            </div>
        </div>
    );
};

export default UserProfile;
