// AuthProvider.js
import {onAuthStateChanged,} from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {auth} from "./firebase.js";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(false);
            setUser(currentUser);
            if (currentUser) {
                setAccessToken(await currentUser.getIdToken(true));
            }
        });

        return () => unsubscribe();
    }, []);

    const authValue = {
        user,
        loading,
        accessToken,
    };

    return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
children: PropTypes.node.isRequired,
};

export default AuthProvider;
