import { useEffect, useContext, useState } from 'react'
import {backend_url} from "../../config.js";
import { Link, useNavigate } from "react-router-dom";

export default function UserDetailsComponent ({postCreator}) {
    return (
        <div className="flex justify-between px-3 py-2.5 items-center">
            <div className="flex space-x-3 items-center">
                <Link to={`/profile/` + postCreator?.link}><img draggable="false" src= {backend_url + "/default_profile_pic2.jpeg"} className="w-10 h-10 rounded-full object-cover" alt="avatar" /></Link>

                <div className='flex flex-col'>
                    <Link to={`/profile/` + postCreator?.link} className="text-black text-sm font-semibold">
                        {postCreator?.firstname} {postCreator?.lastname}
                    </Link>

                    <Link to={`/profile/` + postCreator?.link} className="text-black text-sm">
                        {postCreator?.role}
                    </Link>
                </div>
            </div>
        </div>
    )
}
