
import categories from "./categories.json";


let categories_react_select = categories.categories.map((category) => {
    console.log(category);
    if (typeof category === 'string') {
        return {
            label: category,
            value: category,
        }
    } else {
        return {
            label: Object.keys(category)[0],
            value: Object.keys(category)[0],
        }
    }
});
export default categories_react_select;
